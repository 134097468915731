.auth-form {
  border-radius: 24px;
  width: 660px;
}

.auth-form-content {
  border-radius: 24px;
  position: relative;
  z-index: 1;
  padding: 32px;
  padding-top: 40px;
  border: 1px solid transparent;
  background: linear-gradient(var(--bg-main), var(--bg-main)) padding-box, var(--gradient) border-box;
}

.auth-form-logo {
  width: 100%;
  max-width: 300px;
}