.track-type {
  font-size: 14px;
  font-family: var(--font-bold);
  &-active {
    color: var(--orange);
  }

  &-pre {
    color: var(--text-muted);
  }
}

.track-title {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.track-title-inline {
  flex-direction: row;
  align-items: center;
}