.modal-close {
  position: absolute;
  right: 16px;
  top: 16px;
  path {
    fill-opacity: 0.4;
  }
}

.modal-footer-btns {
  display: flex;
  margin-top: 40px;
  justify-content: flex-end;
}

.modal-footer-btns button {
  width: 275px;
}

.modal-cover-bg {
  width: 300px;
  height: 300px;
  background: url("../assets/images/cover_bg.svg") no-repeat center center;
  border-radius: 24px;
  border: 1px solid var(--divider);
  overflow: hidden;
}

.modal-cover-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.moder-album-item {
  padding: 8px 24px;
  background-color: rgba(228, 232, 238, 0.1);
  transition: var(--transition);
  cursor: pointer;
  position: relative;
  border: 1px solid transparent;
  border-radius: 16px;
  margin-top: 16px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border-radius: 16px;
    transition: var(--transition);
    border: 1px solid transparent;
    background: linear-gradient(#323237, #323237) padding-box, var(--gradient) border-box;
    pointer-events: none;
    opacity: 0;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  &.active {
    background-color: #CC4DDF;

    &:before {
      opacity: 0;
    }

    .form-check .form-check-input:checked + .form-check-custom {
      background-color: #fff;

      path {
        fill: #CC4DDF;
      }
    }
  }
}

.moder-album-item-content {
  position: relative;
  display: flex;
  align-items: center;

  img {
    width: 56px;
    height: 56px;
    min-width: 56px;
    border-radius: 8px;
    margin-right: 16px;
    margin-left: 24px;
  }
}

.form-group-labels {
  label {
    font-size: 14px;
    line-height: 21px;
    font-family: var(--font-bold);
    color: rgba(255, 255, 255, 0.8) !important;
  }
}