:root {
  --bg-main: #07060C;
  --text-color: #ffffff;
  --orange: #ffc700;
  --green-active: #85e294;
  --green-hover: #a3f2b0;
  --green: #8CEF9C;
  --red: #FC4646;
  --purple: #CC4DDF;
  --blue-light: #64C0E0;
  --blue: #1E46E8;

  --bg-secondary: rgba(255, 255, 255, 0.1);
  --text-dark: rgba(255, 255, 255, 0.64);
  --text-muted: rgba(255, 255, 255, 0.5);
  --divider: rgba(255, 255, 255, 0.16);

  --transition: all .3s ease;
  --font-regular: 'Montserrat-Regular', sans-serif;
  --font-medium: 'Montserrat-Medium', sans-serif;
  --font-bold: 'Montserrat-Bold', sans-serif;
  --gradient: linear-gradient(96.37deg, #CC4DDF 13.48%, #64C0E0 34.4%, #1E46E8 100.92%);
}

