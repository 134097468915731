.page__group__item-icon {
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -16px;
}


.performers-list {
  padding-top: 16px;
}

.performers-list-item {
  display: flex;
  align-items: center;
  margin-top: 16px;
  position: relative;

  .card {
    border-radius: 60px;


    &:after {
      border-radius: 60px;
    }
  }

  .btn-third {
    background-color: rgba(255, 255, 255, 0.1);

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
  .reject.card__item__status-icon {
    color: var(--red);
  }
}

.performers-list-item-id {
  transition: var(--transition);
  width: 50px;
  min-width: 50px;
}

.performers-list-item:hover {
  z-index: 2;

  .performers-list-item-id {
    color: var(--text-color);
  }
}

.performers-list-item-utls {
  color: var(--text-muted);
  align-self: center;
  margin-left: 16px;
}

.performers-list-item-utls a {
  max-width: 160px;
  margin-right: 8px;
}

.performers-list-item .card-body {
  padding: 16px;
}

.performers-list-item .avatar-logo {
  width: 56px;
  height: 56px;
  min-width: 56px;
}

.performers-list-item .card__item__status-icon {
  width: 40px;
  font-size: 16px;
  min-width: 40px;
  height: 40px;
  background-color: var(--bg-main);
}

.performers-list-item-reject {
  top: 2px;
  right: 2px;
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: var(--red);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;

  svg {
    display: none;
  }
}

.performer {
  position: relative;
  background-color: var(--purple);
  border-radius: 24px;
  padding: 16px;
  display: flex;
  z-index: 1;
  overflow: hidden;
}

.performer-wrap {
  position: relative;
  width: 100%;
  max-width: 100%;
  transition: var(--transition);
}

.performer-wrap:after {
  pointer-events: none;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: var(--gradient);
  filter: blur(50px);
  border-radius: 24px;
}

.performer .avatar-logo {
  width: 104px;
  height: 104px;
  min-width: 104px;
  margin-right: 16px;
  background-color: var(--blue-light);
}

.performer a.text-truncate {
  max-width: 300px;

  &:hover {
    color: #fff;
  }
}

.performer-form {
  margin-bottom: 40px;
  padding: 32px;
  border-radius: 24px;
  border: 1px solid transparent;
  background: linear-gradient(var(--bg-main), var(--bg-main)) padding-box, var(--gradient) border-box;

  .close-btn {
    margin-top: -24px;
    margin-right: -16px;
  }
}

.performer-form-footer {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.performer-form-footer button {
  min-width: 275px;
  padding-left: 24px;
  display: flex;
  margin-left: 16px;

}

.performer-info-links {
  display: flex;
  flex-direction: column;
  align-self: baseline;
  transition: var(--transition);
  opacity: 1;
  flex: 1;
  position: relative;
}

.performer-info-links-wrap {
  position: absolute;
  left: 96px;
}

.performer-info {
  align-self: flex-start;
  min-width: 242px;
}

.performer-info .card__item__status-icon div svg {
  width: 16px;
  height: 16px;
}

.performer .close-btn {
  align-self: flex-start;
  background-color: var(--bg-secondary) !important;

  &:after {
    display: none;
  }

  path {
    transition: var(--transition);
  }

  &:hover {
    background: rgba(255, 255, 255, 0.2) !important;
    color: #fff;

    path {
      fill-opacity: 1;
    }
  }
}

.track-generate-cover {
  position: absolute;
  left: 134px;
  bottom: 12px;
}

.track-cover-requirements {
  position: absolute;
  right: 12px;
  top: 0px;
  font-size: 14px;
  line-height: 21px;
  color: var(--purple);
  cursor: pointer;
  transition: var(--transition);
  display: flex;
  align-items: center;
}

.track-cover-requirements:hover {
  text-decoration: underline;
}

.tracks-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  &.active .card{
    background: rgba(255, 255, 255, 0.16);
  }
}

.tracks-list-item .text-status {
  transition: var(--transition);

  &.approve {
    opacity: 0;
  }
}

.tracks-list-item-download {
  opacity: 0;
  transition: var(--transition);
  cursor: pointer;
}

.tracks-list-item-id {
  transition: var(--transition);
}

.tracks-list-item-controls {
  transition: var(--transition);
  opacity: 0;
  display: flex;
  align-items: center;

  .btn {
    background-color: rgba(255, 255, 255, 0.1);

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}

.tracks-list-item .card {
  cursor: default;
}

.tracks-list-item .card__item__status-icon {
  width: 40px;
  height: 40px;
  min-width: 40px;
  font-size: 16px;
  transition: var(--transition);
}


.tracks-list-item:hover {
  .tracks-list-item-id {
    color: var(--text-color);
  }

  .tracks-list-item-download {
    opacity: 1;
  }

  .card__item__status-icon {
    &.approve {
      color: var(--green);
    }

    &.reject {
      color: var(--red);
    }

    &.review {
      color: var(--text-muted);
    }
  }

  .text-status {
    &.approve {
      opacity: 1;
    }
  }

  .tracks-list-item-controls {
    opacity: 1;
  }

  .player {
    .ctrl {
      opacity: 1;
    }
    .player-id, .ctrl-animation {
      opacity: 0;
    }

  }
}


.track-album-cover {
  position: relative;
  width: 40px;
  height: 40px;
  min-width: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
}


.additional-information {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 32px;
  transition: var(--transition);
  color: var(--text-muted);

  path, svg {
    transition: var(--transition);
  }
}

.additional-information-separator {
  transition: var(--transition);
  flex: 1;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 12px;

}

.additional-information:hover {
  color: var(--text-color);

  .additional-information-separator {
    background-color: #fff;
  }

  path {
    stroke-opacity: 1;
  }
}

.additional-information.visible svg {
  transform: rotate(-180deg);
}

.track-type-first-entry {
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  padding: 16px 24px;
  border-radius: 24px;
  cursor: pointer;
  width: 435px;
  margin-right: 32px;

  &:after {
    pointer-events: none;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border: 1px solid transparent;
    background: linear-gradient(#2F2E33, #2F2E33) padding-box, var(--gradient) border-box;
    border-radius: 24px;
    opacity: 0;
    transition: var(--transition);
  }

  &:hover {
    &:after, .track-type-first-entry-arrow {
      opacity: 1;
    }

  }
}

.track-type-first-entry-content {
  position: relative;
  z-index: 1;
}

.track-type-first-entry-arrow {
  transition: var(--transition);
  transform: rotate(180deg);
  opacity: 0;
}

.compose-track-type-btns {
  display: flex;
  align-items: center;
  margin-bottom: 32px;

  button {
    border: 1px solid transparent;
  }
}

.performer-wrap.draft, .performer-wrap.review {
  .performer {
    background-color: rgba(255, 255, 255, 0.1);
  }
  &:after {
    display: none;
  }
}
.performer-wrap.reject {
  .performer {
    background-color: rgba(252, 70, 70, 0.24);
  }
  &:after {
    display: none;
  }
}