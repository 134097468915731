@font-face {/*400*/
  font-family: 'Montserrat-Regular';
  src: url('Montserrat-Regular.eot');
  src: url('Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
  url('Montserrat-Regular.woff') format('woff'),
  url('Montserrat-Regular.ttf') format('truetype'),
  url('Montserrat-Regular.svg#webfont') format('svg');
}
@font-face {/*500*/
  font-family: 'Montserrat-Medium';
  src: url('Montserrat-Medium.eot');
  src: url('Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
  url('Montserrat-Medium.woff') format('woff'),
  url('Montserrat-Medium.ttf') format('truetype'),
  url('Montserrat-Medium.svg#webfont') format('svg');
}
@font-face {/*700*/
  font-family: 'Montserrat-Bold';
  src: url('Montserrat-Bold.eot');
  src: url('Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
  url('Montserrat-Bold.woff') format('woff'),
  url('Montserrat-Bold.ttf') format('truetype'),
  url('Montserrat-Bold.svg#webfont') format('svg');
}
/* -------------------------- */
