.service-arpeggy {
  .auth-form,
  .albums-list-item,
  .albums-list-item:before,
  .albums-list-item:hover .albums-list-item-cover,
  .albums-list-item:hover .albums-list-item-cover img,
  .albums-list-item-cover,
  .custom-search-select-list-item,
  .albums-list-item-cover img,
  .moder-album-item:before,
  .moder-album-item,
  .moder-album-item-content img,
  .track-album-cover,
  .track-album-cover img,
  .album-head-cover img,
  .album-head,
  .performer-compose-gallery-list,
  .performer-compose-gallery-list-item img,
  .form-control-wrap,
  .input-group .form-group:first-child .form-control, .input-group .form-group:first-child .form-control-border,
  .input-group .form-group:last-child .form-control, .input-group .form-group:last-child .form-control-border,
  .report-filters-content img,
  .report__list__item-icon,
  .performer {
    border-radius: 0;
  }

  .input-group .form-group:last-child .form-control, .input-group .form-group:last-child .form-control-border {
    border-right-width: 2px;
  }

  .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -2px;
  }

  .btn-icon {
    display: none;
  }

  .btn .react-icon {
    display: none;
  }

  .bold-20 {
    font-size: 24px;
    line-height: 27px;
  }

  .text-danger {
    color: var(--red) !important;
  }

  .bg-gradient:after {
    background: var(--gradient);
  }

  .auth-form-content {
    border-radius: 0;
    border: 2px solid #fff;
  }

  .auth-form-logo {
    width: 232px;
  }

  .form-control-gradient {
    display: none;
  }

  .form-control {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 0;
    background-color: var(--bg-main);

    &:focus {
      border: 2px solid rgba(255, 255, 255, 1);
      border-radius: 0;
      background-color: var(--bg-main);
    }

    &::placeholder {
      font-family: var(--font-bold) !important;
    }
  }

  h1, h2, h3, h4 {
    text-transform: uppercase;
  }

  .input-icon {
    z-index: 1;
    background-color: transparent;
    display: none;
  }

  .form-control-icon {
    padding-left: 24px;
  }

  .form-check-custom {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 0;

    path {
      fill: var(--bg-main) !important;
    }
  }

  .form-check .form-check-input:hover + .form-check-custom {
    border-color: #fff;
  }

  .form-check .form-check-input:active + .form-check-custom, .form-check .form-check-input:checked + .form-check-custom {
    background-color: transparent;
    border: none;
    background: var(--gradient);
  }

  .form-group label {
    font-family: var(--font-bold);
    padding-left: 0;
  }

  .form-group label + .text-muted-14 {
    padding-left: 0 !important;
  }

  .close-btn {
    background: transparent;

    svg path {
      fill-opacity: 1;
      transition: var(--transition);
    }

    &:hover svg path {
      fill-opacity: 0.4;
    }
  }


  .icon-trash {
    background-image: url("./icons/trash.svg");
  }

  .icon-edit {
    background-image: url("./icons/edit.svg");
  }

  .icon-question {
    background-image: url("./icons/question.svg");
  }

  .btn-primary {
    background: var(--gradient);
    overflow: visible;
    color: var(--bg-main) !important;

    &:after {
      background: var(--gradient);
      filter: blur(20px);
      z-index: -1;
      border-radius: 24px;
    }

    &:active:after {
      filter: blur(10px);
    }

    &:before {
      z-index: 1;
      border-radius: 24px;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      pointer-events: none;
      opacity: 0;
      transition: var(--transition);
      background: rgba(17, 17, 17, 0.16);
    }

    &:active:before {
      opacity: 1;
    }
  }

  .btn-third, .btn-blue, .btn-secondary {
    overflow: visible;
    border: 1px solid transparent;
    background: linear-gradient(var(--bg-main), var(--bg-main)) padding-box, var(--gradient) border-box;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      pointer-events: none;
      opacity: 0;
      transition: var(--transition);
      background: var(--gradient);
      filter: blur(20px);
      z-index: -1;
      border-radius: 24px;
    }

    &:active:after {
      filter: blur(10px);
    }

    &:hover:after {
      opacity: 1;
    }

    &:before {
      z-index: 1;
      border-radius: 24px;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      pointer-events: none;
      background: var(--bg-main);
    }

    &:active {
      border-color: transparent;
    }

  }

  .btn-component.btn {
    &:after {
      z-index: 0;
    }
  }

  .btn-lg {
    height: 64px;
    min-height: 64px;

    &.btn-third, &.btn-blue, &.btn-secondary {
      &:before {
        border-radius: 40px;
      }
    }
  }

  .btn > div {
    margin-top: 3px;
  }

  header {
    background-color: transparent;
    border-bottom: 2px solid #fff;
  }

  header .logo {
    width: 116px;
    height: auto;
  }

  header .balance {
    font-family: var(--font-bold);
  }

  header .nav {
    justify-content: center;
  }

  header .nav a {
    color: var(--text-muted);

    &:hover {
      color: var(--text-color);
      background-color: transparent;
    }

  }

  header .nav a:not(.disabled-active).active {
    background: var(--gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .lang-select-visible {
    background: transparent;
    border: none;
    transition: var(--transition);

    svg path {
      transition: var(--transition);
    }

    &:hover {
      color: #fff;

      svg path {
        fill-opacity: 1;
      }
    }
  }

  .lang-select-icon {
    background-color: transparent;
  }

  .custom-search-select-list {
    background-color: var(--bg-main);
    border: 2px solid #fff;
    border-radius: 0;
    top: calc(100% + 20px);
  }

  header .nav-btn {
    background-color: transparent;
  }

  .notifications__bell {
    &:hover {
      background: transparent;

      svg path {
        fill-opacity: 0.4;
      }
    }

    svg path {
      fill: #fff;
      transition: var(--transition);
    }
  }

  .notifications__container__header {
    .delete-all {
      font-family: var(--font-bold);
      font-size: 12px;
      line-height: 24px;

      &:hover {
        color: #fff;
      }
    }
  }

  .notifications__container__items__item .text .date {
    color: var(--text-muted);
  }

  .delete-button {
    svg path {
      fill: #fff;
    }

    &:hover {
      opacity: 0.4;
    }
  }

  .notifications__bell__badge {
    background-color: var(--red);
    border: none;
    top: 8px;
    right: 8px;
    width: 8px;
    height: 8px;

    span {
      display: none;
    }
  }

  .avatar-logo {
    background: var(--gradient);
    color: var(--bg-main);
  }

  .header-popover-content {
    border-radius: 0;
    border: 2px solid #fff;
    top: calc(100% + 20px);
  }

  .page-title {
    text-transform: uppercase;
  }

  .albums-list-item-btn {
    overflow: visible;
  }

  .music-platforms-union-list {
    border: 2px solid #fff;
    border-radius: 0;
  }

  .status-icon.review + .albums-list-item-status-name {
    color: var(--yellow);
  }

  .status-icon.reject + .albums-list-item-status-name {
    color: var(--red);
  }

  .albums-list-item-content:before {
    display: none;
  }

  .albums-list-item-controls {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 0;
    border-radius: 0 0 24px 0;
    background-color: #323237;
    transition: none;

    & > svg {
      transform: rotate(90deg);
      margin-right: 8px;
      margin-bottom: 4px;
    }
  }

  .albums-list-item-status {
    left: auto;
    right: -4px;
    border-radius: 0 0 0 24px;
    flex-direction: row-reverse;
  }

  .albums-list-item-status-name {
    font-family: var(--font-bold);
    font-size: 8px;
    line-height: 15px;
    padding-left: 12px;
    padding-right: 4px;
  }

  .warning-line-with-btn {
    background: linear-gradient(90deg, rgba(254, 64, 128, 0.16) 0%, rgba(254, 64, 128, 0) 100%);
    border-radius: 0;

    .text-danger {
      font-size: 12px;
      padding-left: 0;
    }

    .warning-line-with-btn-icon {
      margin-right: 0;
      margin-left: 0;

      &.info {
        background-image: url("../../theme/arpeggy/icons/info.svg");
      }

      &.warning {
        background-image: url("../../theme/arpeggy/icons/warning.svg");
      }

      background-color: transparent;
    }
  }


  .performer-info .bold-20 {
    font-size: 24px;
  }

  .performer-info-status {
    font-family: var(--font-bold);
    flex-direction: row-reverse;
    justify-content: flex-end;
    font-size: 12px;

    .text-medium-14 {
      font-family: var(--font-bold) !important;
      font-size: 12px !important;
    }

    &.approve {
      color: var(--green);
    }

    .text-14 {
      font-size: 12px !important;
      line-height: 15px;
    }
  }

  .status-icon {
    &.approve {
      background-image: url("./icons/approve.svg");
    }

    &.reject {
      background-image: url("./icons/reject.svg");
    }

    &.draft {
      background-image: url("./icons/draft.svg");
    }

    &.review {
      background-image: url("./icons/review.svg");

      &.review-clock {
        background-image: url("./icons/clock.svg");
      }
    }
  }

  .card__item__status-icon.status-icon {
    background-color: transparent;
    margin-right: 0 !important;
    margin-left: 4px;
    margin-top: -1px;

    &.approve {
      background-size: 20px;
      background-image: url("./icons/approve.svg");
    }
  }

  .performer-wrap.draft .performer, .performer-wrap.review .performer {
    background-color: rgba(255, 255, 255, 0.08);
  }

  .breadcrumbs {
    font-size: 14px;
    line-height: 24px;

    .current {
      color: var(--red);
    }
  }

  .album-header.visible .performer-wrap .btn-third {
    opacity: 0;
    pointer-events: none;
  }

  .page__group__item {
    background: transparent;
    color: var(--text-muted);

    &:hover {
      color: #fff;
    }

    &.active {
      background: var(--gradient);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .page__group__status__item-icon {
      opacity: 0.4;
      margin-left: 2px;
      top: -2px;
      position: relative;

      &.approve {
        background-image: url("./icons/approve_white.svg");
      }
    }
  }

  .performer-edit-btn {
    .icon-edit {
      display: none;
    }

    .ms-3 {
      margin-left: 0 !important;
    }
  }


  .title-icon {
    display: none;
  }

  .albums-list-item-controls-hidden {
    border-radius: 0;
    border: 2px solid #fff;
    top: 100%;
    bottom: auto;
    left: 0;
    right: auto;
    background-color: var(--bg-main);

  }

  .albums-list-item-controls-hidden-item {

    padding-right: 12px;
    flex-direction: row-reverse;
    border-radius: 0;

    .text-medium {
      font-family: var(--font-bold) !important;
      font-size: 12px;
      padding-left: 8px !important;
    }
  }

  .albums-list-item-controls-icon {
    background-color: transparent;
    margin-left: 0;
  }

  .albums-list-item-title {
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;

    & + div {
      justify-content: center !important;
    }
  }

  .albums-list-empty-bg-item {
    border-radius: 0;
    background-color: rgba(255, 255, 255, 0.08);;
  }

  .albums-list-empty-content {
    border-radius: 0;
    background-color: var(--bg-main);
    border: 2px solid #fff;

    h2 {
      background: var(--gradient);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    p {
      font-size: 12px;
    }
  }

  .albums-list-empty-content-item {
    border-radius: 0;
    font-family: var(--font-bold);
    color: #fff;
    margin-bottom: 8px;
    font-size: 12px;
  }

  .albums-list-empty-footer {
    padding: 0 60px;

  }

  .albums-list-empty-content-item-number {
    background-color: transparent;
    -webkit-text-stroke: 1px #fff;
    color: transparent;
    font-size: 24px;
  }

  .albums-list-empty-tracks {
    font-family: var(--font-bold);
    font-size: 14px;
  }

  .album-info .text-medium {
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.4);
    color: transparent;
    font-family: var(--font-bold);
  }

  .track-title {
    padding-left: 24px !important;

    .bold-20 {
      font-size: 16px;
    }

    .bold {
      font-family: var(--font-regular);
      font-size: 14px;
      line-height: 20px;
    }
  }

  .player-id {
    font-family: var(--font-bold);
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.4);
    color: transparent;
    font-size: 16px;
  }

  .card-active {
    background-color: rgba(255, 255, 255, 0.08);
    border-radius: 0;

    &:after {
      display: none;
    }

    .card-body {
      padding: 0;
      padding-right: 12px !important;
    }

    .player {
      background-color: var(--bg-main);
      border-radius: 0;
    }
  }

  .tracks-list-item-controls {
    font-family: var(--font-bold);
    font-size: 12px;

    .btn-third {
      background: transparent !important;

      &:before, &:after {
        display: none;
      }

      > div {
        margin-top: 0;
      }
    }
  }

  .pagination {
    font-size: 14px;
    color: var(--text-muted);

    .btn:disabled {
      background-color: rgba(255, 255, 255, 0.02) !important;

      path {
        fill-opacity: 1;
        stroke-opacity: 1;
      }
    }

    select {
      color: var(--text-muted);
    }
  }

  .performer-form {
    border-radius: 0;
    background-color: var(--bg-main);
    border: 2px solid #fff;
  }

  .track-cover-requirements {
    background: var(--gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: var(--font-bold);

    .icon-question {
      background-size: 18px;
      margin-top: -3px;
    }
  }

  .form-file-icon {
    display: none;
  }

  .form-file-label-wrap .text-14 {
    font-family: var(--font-bold);
    font-size: 12px !important;
  }


  .form-group-file {
    border-radius: 0;
    background-color: transparent;
    border: 2px solid rgba(255, 255, 255, 0.3);
  }

  .form-file-list-item {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background-color: rgba(255, 255, 255, 0.08);
    font-size: 12px;
    font-family: var(--font-bold);
    color: #fff;
    padding-top: 3px;
    padding-left: 12px;
    padding-right: 12px;
  }


  .performer-compose-steps {
    padding: 0 0 32px;
    align-self: flex-start;
    justify-content: flex-start;
  }

  .performer-compose-tabs {
    padding: 0;
    padding-right: 40px;
    background: rgba(255, 255, 255, 0.08);
  }

  .performer-compose-tab-item {
    margin: 0;
    height: 8px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background-color: transparent;

    &.active {
      background-color: #fff;
    }
  }

  .performer-compose-form {
    border: 2px solid #fff;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin-top: 0;
    align-items: center;
    display: flex;
    flex-direction: column;
    background: var(--bg-main);

    h4 {
      text-align: center;
    }

    form {
      max-width: 596px;
    }
  }

  .performer-compose-gallery-list {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background-color: rgba(255, 255, 255, .08);
    border: 2px solid rgba(255, 255, 255, 1);

    &:after {
      height: 2px;
      left: -10px;
      background: linear-gradient(to left, #FFFFFF 0%, #595959 100%);

    }
  }

  .performer-compose-gallery-list-item:hover {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    border: 2px solid #fff;
  }

  .performer-compose-gallery-list-item-remove {
    border-radius: 0;
    border: none;
    background: url('../../../assets/images/close.svg') no-repeat center center;
    background-color: rgba(0, 0, 0, .64);
    background-size: 16px;

    &:after, &:before {
      display: none;
    }
  }

  .performer-compose-gallery-btn {
    height: 100%;
  }

  .performer-compose-example {
    font-family: var(--font-bold);
    text-align: center;

    .text-dark-14 {
      color: #fff !important;
    }
  }

  .performer-compose-phone {
    background-image: url("./images/phone.png");
  }

  .performer-compose {
    width: 100%;

    .page-title {
      align-self: flex-start;
    }

    .text-dark-14 {
      color: var(--text-muted) !important;
    }

    .form-group-file label {
      padding: 3px 16px 0;
    }

    .passport_example {
      border-radius: 0;
      border: none;
      background-image: url("./images/passport.png");
    }

    .passport_example-text {
      display: none;
    }
  }

  .passport-docs-list {
    padding-left: 0;
  }

  .passport-docs-list-item {
    padding-top: 2px;
    font-family: var(--font-bold);
    border-radius: 0;
  }

  .compose-performer-modal-finish-congr {
    display: none;
  }

  .compose-performer-finish-gradient {
    background: url("./images/wave.png") no-repeat center top;
    background-size: 900px;
    filter: none;
    justify-content: center;
    display: flex;

    &:before {
      position: absolute;
      top: 32px;
      content: '';
      width: 32px;
      height: 32px;
      background: url("./icons/step.svg") no-repeat center center;
    }

    &:after {
      position: absolute;
      top: 72px;
      content: 'The last step!';
      background: var(--gradient);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: var(--font-bold);
    }
  }

  .performer-compose-finish-content {
    padding-top: 96px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      text-align: center;

      & + div {
        text-align: center;
        color: rgba(255, 255, 255, 0.4) !important;

      }
    }

    .form-group-file {
      align-items: center;
    }

    .form-file-label {
      justify-content: center;

      .react-icon {
        display: none;
      }
    }
  }

  .form-file-label {
    position: relative;
    padding: 3px 16px 0 !important;
    overflow: visible;
    border: 1px solid transparent;
    font-size: 12px !important;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      pointer-events: none;
      opacity: 0;
      transition: var(--transition);
      background: var(--gradient);
      filter: blur(20px);
      border-radius: 24px;
    }

    &:hover:after {
      opacity: 1;
    }

    &:before {
      z-index: 1;
      border-radius: 24px;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      pointer-events: none;
      background: var(--bg-main);
    }

    & > div > div > div:first-child {
      display: none;
    }

    .ps-2 {
      z-index: 1;
      padding-left: 0 !important;
    }
  }

  .form-group-file-placeholder {
    font-size: 14px;
  }


  .text-gradient {
    background: var(--gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .modal-footer-btns, .performer-form-footer {
    .btn {
      min-height: 64px;
      height: 64px;
      -webkit-border-radius: 32px;
      -moz-border-radius: 32px;
      border-radius: 32px;

      &:before {
        -webkit-border-radius: 32px;
        -moz-border-radius: 32px;
        border-radius: 32px;
      }
    }
  }

  .moder-album-item {
    background-color: rgba(255, 255, 255, 0.08);
    border: 2px solid var(--bg-main);
    padding-top: 0;
    padding-bottom: 0;
    height: 72px;

    &:before {
      display: none;
    }

    &:hover {
      border-color: #fff;
    }

    &.active {
      border-color: #fff;
      background-color: rgba(255, 255, 255, 0.08);
    }
  }

  .moder-album-item-content {
    height: 100%;

    .form-check {
      padding-left: 48px;
    }

    img {
      margin: 0;
      width: 68px;
      height: 68px;
      min-width: 68px;
    }

    .cover-stub svg {
      width: 72px;
    }
  }

  .table-head {
    font-family: var(--font-bold);
    color: #fff;
    border-bottom: none;
    padding-bottom: 0;
  }

  .documents-table {
    .bold-20 {
      font-size: 16px;
    }
  }

  .doc-status .text-medium {
    font-family: var(--font-bold) !important;
  }

  .report-filters-block {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background: rgba(255, 255, 255, 0.08);
  }
  .report__list__item-icon {
    height: 100px;
    width: 100px;
    min-width: 100px;
  }
  .reports-total {
    background: rgba(255, 255, 255, 0.08);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
}