.marquee {
  position: relative;
  &:before,
  &:after {
    content: '';
    height: 100%;
    top: 0;
    width: 20px;
    position: absolute;
    z-index: 1;
    pointer-events: none;
  }
  &:before {
    left: -1px;
    background: linear-gradient(90deg, var(--bg-main) 0%, rgba(255, 255, 255, 0) 100%);
  }

  &:after {
    right: -1px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, var(--bg-main) 100%);
  }
  .items-wrap {
    position: relative;
    overflow: hidden;
    user-select: none;
    gap: 20px;


    .items {
      display: flex;
      gap: 20px;

      .item {
        transition: all 0.1s linear;
      }
    }
  }

  .marquee {
    animation: scroll 30s linear infinite;
  }

  //.items-wrap:hover .marquee {
  //  animation-play-state: paused;
  //}

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-500%);
    }
  }
}
.card:hover, .track-block:hover {
  .marquee {
    &:before {
      background: linear-gradient(90deg, var(--bg-main) 0%, rgba(255, 255, 255, 0) 100%);
    }

    &:after {
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, var(--bg-main) 100%);
    }
  }
}