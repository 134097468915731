.album-list-header {
  display: flex;
  margin-bottom: 32px;
  justify-content: space-between;
  align-items: center;
  height: 48px;
}

.albums-list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  margin-bottom: 32px;
}

.albums-list-item {
  cursor: pointer;
  position: relative;
  height: 288px;
  width: 220px;
  min-width: 220px;
  padding: 8px;

  border: 1px solid transparent;
  border-radius: 16px;
  margin: 12px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border-radius: 16px;
    border: 1px solid transparent;
    background: linear-gradient(#2F2E33, #2F2E33) padding-box, var(--gradient) border-box;
    pointer-events: none;
    opacity: 0;
  }

  &.approve {
    .albums-list-item-content {
      &:after, &:before {
        display: none;
      }
    }

    .albums-list-item-status {
      display: none;
    }

    .albums-list-item-cover img {
      opacity: 1;
    }


  }

  &.reject {
    .albums-list-item-status {
      //background: url("../../assets/images/reject_red.svg") no-repeat center center;
    }
    .albums-list-item-status-name {
      color: var(--red);
    }
    .albums-list-item-cover:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      right: 0;
      border-radius: 8px;
      background: linear-gradient(180deg, rgba(47, 46, 51, 0) 10.31%, #07060C 100%);
    }

  }

  &.review {
    .albums-list-item-status {
      background-color: var(--bg-main);
    }
  }

  &.draft, &.approve, &.fill-profile {
    background-color: #201F24;

    .albums-list-item-status {
      background-color: #201F24;
    }

    .albums-list-item-title {
      color: #fff
    }

    .albums-list-item-cover:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      right: 0;
      border-radius: 8px;
      background: linear-gradient(180deg, rgba(47, 46, 51, 0.00) 20.31%, #201F24 100%);
    }
  }
}

.albums-list-item-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &:after {
    content: '';
    height: 16px;
    width: 16px;
    position: absolute;
    left: 40px;
    top: -5px;
    background: url('./assets/corner.svg') no-repeat center;
    opacity: 0;
    //transition: var(--transition);
  }

  &:before {
    content: '';
    z-index: 1;
    height: 16px;
    width: 16px;
    position: absolute;
    left: -4px;
    top: 40px;
    background: url('./assets/corner.svg') no-repeat center;
    opacity: 0;
    //transition: var(--transition);
  }
}

//
//.albums-list-item.review {
//  &:after {
//    content: '';
//    position: absolute;
//    left: 0;
//    right: 0;
//    bottom: 0;
//    top: 0;
//    border-radius: 16px;
//    transition: var(--transition);
//    color: rgba(255, 255, 255, 0.4);
//  }
//}

.albums-list-item-cover {
  //overflow: hidden;
  padding: 8px;
  border-radius: 4px;
  width: 100%;
  height: 203px;
  transition: var(--transition);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;

}

.albums-list-item-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 4px;
  transition: var(--transition);
  opacity: 0.4;
}

.albums-list-item-btn {
  transition: var(--transition);
  overflow: hidden;
  z-index: 1;

  .btn {
    width: 100%;
    min-height: 36px;
    border-radius: 18px;
    font-size: 12px;
    line-height: 21px;
  }
}

.albums-list-item-title {
  font-size: 16px;
  font-family: var(--font-bold);
  margin-top: 8px;
  color: var(--text-muted);
  align-self: flex-end;
}

.albums-list-item-status {
  min-width: 48px;
  height: 48px;
  position: absolute;
  left: -4px;
  top: -4px;
  background: #131218;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 16px 0 24px 0;
  font-size: 24px;
  z-index: 1;
  padding-left: 4px;
}

.albums-list-item-status-name {
  font-size: 12px;
  padding-left: 4px;
  padding-right: 16px;
  display: none;
}

.albums-list-item-status-text {
  font-family: var(--font-medium);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 16px 16px 78px;
  z-index: 2;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
  transition: var(--transition);
}

.albums-list-item-status-content {
  height: 40px;
  width: 40px;
  border-radius: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition);
  font-size: 18px;
}

.albums-list-item:hover {
  background-color: #2F2E33;

  &:before, &:after {
    opacity: 1;
  }

  .albums-list-item-content {
    &:after, &:before {
      opacity: 1;
    }
  }

  .albums-list-item-cover {
    padding: 0;
    border-radius: 8px;
    overflow: visible;

    &:after {
      background: linear-gradient(180deg, rgba(47, 46, 51, 0.00) 20.31%, #2F2E33 100%);
    }

    img {
      border-radius: 8px;
    }


  }

  .albums-list-item-status {
    background-color: #323237;
  }

  .albums-list-item-status-text {
    opacity: 1;
  }


  .albums-list-item-controls {
    opacity: 1;
  }

  .albums-list-item-status-name {
    display: block;
  }

  .music-platforms {
    background: rgba(255, 255, 255, 0.1);
  }

  .music-platforms-item-visible-icon path {
    fill-opacity: 1;
  }

  .music-platforms-list-item.union {
    color: #fff;
  }
}

.albums-list-item-controls {
  margin-left: 12px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.16);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: var(--transition);
  width: 40px;
  min-width: 40px;
  opacity: 0;
}

.albums-list-item-controls-hidden {
  position: absolute;
  bottom: calc(100% + 8px);
  right: -8px;
  background: var(--bg-main);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .albums-list-item-controls-hidden-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.albums-list-item-controls-hidden-item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  padding: 4px;
  border-radius: 23px;
  transition: var(--transition);
  width: 100%;
}

.albums-list-item-controls-icon {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}


.album-head {
  height: 136px;
  display: flex;
  flex: 1;
  border-radius: 24px;
  padding: 16px;
  background: rgba(255, 255, 255, 0.1);

  &.reject {
    background: rgba(252, 70, 70, 0.4);
  }

  &.approve {
    background: var(--blue-light);
  }
}

.performer-wrap + .album-head-wrap {
  margin-left: 24px;
}

.album-head-cover {
  position: relative;
  width: 104px;
  height: 104px;
  min-width: 104px;
  box-shadow: 0 4px 16px rgba(29, 28, 33, 0.4);
  border-radius: 8px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.album-head-wrap {
  flex: 1;
}

.album-header {
  display: flex;
  align-items: flex-end;
}

.album-header.visible {
  .performer-info-links {
    opacity: 0;
  }

  .performer-wrap {
    max-width: 458px;

    .btn-third {
      padding: 0;
      width: 48px !important;
      min-width: 48px !important;

      span {
        display: none;
      }
    }

    .text-danger {
      display: none;
    }
  }
}

.albums-list-empty {
  display: flex;
  justify-content: center;
  flex: 1;
  padding-bottom: 40px;
  align-items: flex-start;
  position: relative;
}

.albums-list-empty-bg {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 32px;
  right: 0;
  overflow: hidden;
  pointer-events: none;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    background: linear-gradient(to top, var(--bg-main) 10%, rgba(255, 255, 255, 0) 100%);
  }
}

.albums-list-empty-bg-item {
  flex: 1;
  margin: 0 12px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  height: 288px;
}

.albums-list-empty-content {
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(20px);
  padding: 32px 40px 40px;
  border-radius: 40px;
  max-width: 756px;
}

.albums-list-empty-content-item {
  display: flex;
  align-items: flex-start;
  padding: 4px;
  padding-right: 32px;
  background-color: rgba(255, 255, 255, 0.1);
  line-height: 22px;
  color: rgba(255, 255, 255, 0.8);
  border-radius: 24px;
  font-size: 14px;
  margin-bottom: 4px;
  .text-pre-wrap {
    padding: 9px 0;
  }
}

.albums-list-empty-content-item-number {
  width: 40px;
  height: 40px;
  min-width: 40px;
  background-color: #201F24;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: var(--font-bold);
  color: #fff;
  margin-right: 12px;
}

.albums-list-empty-tracks {
  display: flex;
  align-items: center;
  margin: 8px 0;
  padding: 8px 0;
  transition: var(--transition);
  cursor: pointer;
  color: var(--text-dark);

  path {
    transition: var(--transition);
  }

  &:hover {
    color: #fff;

    .albums-list-empty-tracks-line {
      background-color: #fff;
    }

    path {
      stroke-opacity: 1;
    }
  }
}

.albums-list-empty-tracks-line {
  transition: var(--transition);
  flex: 1;
  margin: 0 16px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
}