@import "constants";

$primary: #ffffff;
$color: #ffffff;
$success: #8CEF9C;
$light: rgba(255, 255, 255, 0.1);
$secondary: rgba(255, 255, 255, 0.1);
$danger: #FC4646;
$dark: rgba(255, 255, 255, 0.64);
$warning: #ffc700;
$body-bg: rgba(255, 255, 255, 0.1);
$border-color: var(--divider);
$box-shadow: none;
$form-select-box-shadow: none;
$btn-box-shadow: none;
$btn-border-width: 0;
$btn-border-radius-sm: 12px;
$btn-border-radius: 24px;
$btn-font-family: var(--font-bold);
$text-muted: var(--text-muted);
$btn-line-height: 24px;
$btn-line-height: 17px;
$input-bg: var(--bg-main);
$input-box-shadow: none;
$input-focus-box-shadow: none;
$input-focus-border-color: #ffc700;
$input-border-radius: 14px;
$input-border-color: var(--divider);
$input-placeholder-color: var(--text-muted);
$input-plaintext-color: #ffc700;
$input-font-family: var(--font-bold);
$input-font-size: 14px;
$card-border-radius: 24px;
$card-bg: var(--divider);
$card-border-width: 0;
$card-spacer-x: 8px;
$card-spacer-y: 8px;
$progress-bar-bg: var(--divider);
$progress-bar-bg: var(--bg-main);
$progress-height: 6px;
$progress-border-radius: 2px;
//$btn-link-color: $text-dark;
@import "bootstrap";



#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

* {
  outline: none;
}

html {
  scroll-behavior: smooth;
  margin-right: calc(-1 * (100vw - 100%));
  overflow-x: hidden;
  background-color: var(--bg-main);
}

.app,
.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: all 600000s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

body {
  font-family: var(--font-regular);
  font-size: 16px;
  background-color: var(--bg-main);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: var(--text-color);
}

.app {
  min-width: 1280px;
  overflow-x: hidden;
}

.w-60 {
  width: 60% !important;
}

.w-40 {
  width: 40% !important;
}

.w-30 {
  width: 30% !important;
}

.w-20 {
  width: 20% !important;
}

.w-15 {
  width: 15% !important;
}

.w-10 {
  width: 10% !important;
}

.w-5 {
  width: 5% !important;
}

.zoundo {
  &-pending {
    color: var(--bs-secondary);
  }

  &-active {
    color: var(--bs-success);
  }

  &-blocked {
    color: var(--bs-danger);
  }
}

label.req {
  font-weight: bolder;

  &:before {
    content: '*';
    color: var(--bs-danger);
    margin-right: 3px;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-move {
  cursor: move !important;
}

.cursor-help {
  cursor: help !important;
}

.review-status {
  &-draft {
    color: var(--bs-secondary);
  }

  &-approved {
    color: var(--bs-success);
  }

  &-rejected {
    color: var(--bs-danger);
  }

  &-in-review {
    color: var(--bs-primary);
  }
}

.user-status {
  &-in-review {
    color: var(--bs-primary);
  }

  &-active {
    color: var(--bs-success);
  }

  &-rejected {
    color: var(--bs-danger);
  }
}

.btn {
  white-space: nowrap;
  color: var(--text-color);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 48px;
  min-width: 48px;
  overflow: hidden;
  box-shadow: none !important;
  outline: none !important;
}

.btn > div {
  position: relative;
  z-index: 1;
}

.btn:hover {
  color: var(--text-color);
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--text-color);
}


.btn-primary {
  color: var(--text-color) !important;
  background: linear-gradient(96.37deg, #CC4DDF 13.48%, #64C0E0 34.4%, #1E46E8 100.92%);
}

.btn-primary:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  opacity: 0;
  transition: var(--transition);
  background: rgba(255, 255, 255, 0.2);
}

.btn-primary:active:after {
  background: rgba(255, 255, 255, 0.1);
}

.btn-primary:hover:after {
  opacity: 1;
}

.btn-blur {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
}


.btn-xs {
  padding: 0.2rem 0.4rem;
  font-size: 0.75rem;
  border-radius: 0.2rem;
}


.btn-40 {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  padding: 0;
}

.btn-secondary {
  color: var(--text-color) !important;
  border-radius: 24px;
  transition: var(--transition);
  border: 1px solid transparent;
  background: linear-gradient(#201F24, #201F24) padding-box, var(--gradient) border-box;
}


.btn-secondary:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #2F2E33;
  pointer-events: none;
  opacity: 0;
  transition: var(--transition);
}


.btn-secondary:hover {
  &:after {
    opacity: 1;
  }
}

.btn-third {
  color: var(--text-color) !important;
  border-radius: 24px;
  transition: var(--transition);
  border: none;
  background-color: rgba(255, 255, 255, 0.1);

  &:focus {
    background-color: rgba(255, 255, 255, 0.15);

    &:active {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}


.btn-warning {
  color: #fff;

  &:hover {
    color: #fff;
  }

  &:active {
    color: #fff !important;
  }

  path {
    fill: #fff;
  }
}


.btn-blue {
  transition: var(--transition);
  background-color: var(--blue);

  &:active {
    background-color: var(--blue);

    &:focus {
      background-color: #1C40D2;
    }
  }

  &:hover {
    background-color: #3459EA;
  }
}


a.disabled {
  color: #ccc !important;
  cursor: not-allowed;
}

.bg-light {
  background-color: var(--bg-secondary);

  &-green {
    background-color: rgba(70, 159, 118, 0.1);
  }
}

.page-title {
  font-size: 40px;
  line-height: 48px;
}

.page__title__line {
  padding-left: 64px;
  padding-right: 28px;
  display: flex;
  align-items: center;
  width: 100%;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.payout-status {
  &-pending {
    color: #d2ab00;
  }

  &-processing {
    color: var(--bs-primary);
  }

  &-canceled {
    color: var(--bs-secondary);
  }

  &-success {
    color: var(--bs-success);
  }

  &-failed {
    color: var(--bs-danger);
  }
}

.user-id {
  background: #efefef;
  display: flex;
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 0;
  padding-top: 34px;

  &-registrar {
    background: url('../images/avatars/stub-registrar.svg') no-repeat center center;
    background-size: cover;
    color: transparent;
  }

  &-admin {
    background: url('../images/avatars/stub-admin.svg') no-repeat center center;
    background-size: cover;
  }

  &-owner {
    background: url('../images/avatars/stub-owner.svg') no-repeat center center;
    background-size: cover;
  }

  &-author {
    background: url('../images/avatars/stub-author.svg') no-repeat center center;
    background-size: cover;
  }

  &-scout {
    background: url('../images/avatars/stub-scout.svg') no-repeat center center;
    background-size: cover;
  }
}

.x-small {
  font-size: 0.75em;
}

.xx-small {
  font-size: 0.5em;
}

.vertical-separator {
  border-left: 1px solid #eee;
  width: 1px;
  align-self: stretch;
  margin: 0 1rem;
}

h2, h3, h4, h5, h6 {
  margin-bottom: 0;
}

h1 {
  font-family: var(--font-bold);
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 0;

  .second {
    font-family: var(--font-medium);
    color: #d0cdda;
  }
}

h2 {
  font-family: var(--font-bold);
}

.text-24 {
  font-size: 24px;
  line-height: 40px;
}

p {
  margin-bottom: 8px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: var(--bg-main);
}


::-webkit-scrollbar-thumb:horizontal {
  background: var(--bg-main);
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:vertical {
  background: var(--divider);
  border-radius: 2px;
}

::-webkit-scrollbar-track {
  background-color: var(--bg-main);
}

::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  background-color: var(--divider);
  //box-shadow: inset 0 0 10px #e8e8e9;
}

.button {
  border: 0;
  font-family: var(--font-bold);
  font-size: 16px;
  line-height: 1;
  color: var(--text-dark);
  padding: 14px 24px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  user-select: none;
  transition: all ease 0.3s;

  svg {
    margin-left: -8px;
    margin-right: 12px;
  }

  &-primary {
    background-color: var(--orange);

    &:hover {
      background-color: #ffd747;
    }

    &:active {
      background-color: #f3be01;
    }
  }
}

.button-buy {
  background-color: var(--bg-main);
  color: var(--text-dark);
  border: 0;
  border-radius: 12px;
  font-family: var(--font-bold);
  font-size: 12px;
  padding: 2px;
  display: flex;
  width: 100%;
  align-items: center;
  transition: all ease 0.3s;

  &:hover {
    background-color: var(--green-hover);

    .icon {
      background-color: var(--green-hover);
    }
  }

  &:active {
    background-color: var(--green-active);

    .icon {
      background-color: var(--green-active);
    }
  }

  .icon {
    transition: all ease 0.3s;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    background: var(--green);
    border-radius: 10px;
  }

  .text {
    flex-grow: 1;
    margin-right: 28px;
  }
}

.button-free {
  background-color: var(--green);
  height: 32px;
  box-sizing: border-box;
  color: var(--text-dark);
  border: 0;
  border-radius: 12px;
  font-family: var(--font-bold);
  font-size: 12px;
  padding: 2px;
  width: 100%;
  align-items: center;
  transition: all ease 0.3s;

  &:hover {
    background-color: var(--green-hover);
  }

  &:active {
    background-color: var(--green-active);
  }
}

.tox-statusbar__branding {
  display: none;
}

a {
  color: rgba(255, 255, 255, 0.4);
  transition: var(--transition);
}

.breadcrumb {
  font-size: 14px;

  &-item {
    a {
      text-decoration: none;

      &.active {
        color: var(--text-muted);
      }
    }
  }
}

.divider {
  height: 1px;
  background-color: var(--divider);
  margin: 12px 0;
}

.text-muted-14 {
  color: var(--text-muted);
  font-size: 14px;
  line-height: 20px;
}

.text-muted-12 {
  color: var(--text-muted);
  font-size: 12px;
  line-height: 18px;
}

.text-yellow {
  color: var(--orange);
}

.text-14 {
  font-size: 14px !important;
  line-height: 21px;
}

.text-medium-14 {
  font-size: 14px !important;
  line-height: 21px;
  font-family: var(--font-medium);
}

.text-12 {
  font-size: 12px !important;
  line-height: 18px;
}

.muted {
  color: var(--text-dark);
}

.bold {
  font-family: var(--font-bold);
}

.text-regular {
  font-family: var(--font-regular);
}

.bold-14 {
  font-family: var(--font-bold);
  font-size: 14px;
  line-height: 20px;
}

.bold-12 {
  font-family: var(--font-bold);
  font-size: 12px;
  line-height: 20px;
}

.bold-20 {
  font-family: var(--font-bold);
  font-size: 20px;
  line-height: 30px;
}

.bold-24 {
  font-family: var(--font-bold);
  font-size: 24px;
  line-height: 32px;
}


.text-medium {
  font-family: var(--font-medium);
}


.badge-white {
  white-space: nowrap;
  background: #FFFFFF;
  border-radius: 20px;
  padding: 3px 12px;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  font-family: var(--font-bold);
  color: var(--text-muted);
}

.btn-icon {
  display: inline-block;
  margin-left: -12px;
}

button.flex-row-reverse .btn-icon {
  margin-left: 0;
  margin-right: -12px;
}

.flex-1 {
  flex: 1;
}

.circle-light-40 {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-secondary);
}

.circle-light-48 {
  width: 48px;
  min-width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-secondary);
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  background-color: var(--bg-secondary);
  border-color: var(--bg-secondary);
  color: #fff;
  opacity: 1;
}

.link-secondary {
  color: var(--text-muted) !important;

  &:focus {
    color: var(--text-muted) !important;
  }

  &:hover, &:active {
    color: var(--text-dark) !important;
  }
}

.text-dark {
  color: var(--text-dark) !important;
}

.text-dark-14 {
  color: var(--text-dark) !important;
  font-size: 14px;
  line-height: 20px;
}

.text-pre-wrap {
  white-space: pre-wrap;
}

.badge-white.tag {
  white-space: nowrap;
  max-width: 85px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
}

.left__content__with__filter {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  padding-left: 64px;
  padding-right: 292px - 16px + 50px;
  margin-top: 24px;
  padding-bottom: 24px;
}

.right_sider__filters {
  z-index: 1;
  position: fixed;
  right: 0;
  bottom: 0;
  width: 292px;
  min-width: 292px;
  background: var(--bg-main);
  height: calc(100vh - 217px);
  padding: 16px;
  border-top-left-radius: 36px;
  overflow: auto;
  margin-right: calc(-1 * (100vw - 100%));
  @media (max-width: 1280px) {
    margin-right: 0;
  }
}

.text-truncate-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}


.page__filters__item {
  padding: 12px 0 12px 8px;
  min-height: 48px;
  border-top: 1px solid var(--bg-secondary);
  margin-right: 0;

  &:last-child {
    border-bottom: 1px solid var(--bg-secondary);
  }
}


.page__filters__item__head {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 32px;
}

.page__filters__item__head svg {
  transition: all .3s ease;
}

.page__filters__item.visible {
  .page__filters__item__head .chevron svg {
    transform: rotate(-180deg);
  }

  .page__filters__item__hidden {
    display: flex;
  }
}

.page__filters__item__hidden {
  padding-top: 16px;
  flex-wrap: wrap;
  margin: 0 -4px;
  display: none;
}

.page__filters__price {
  position: relative;
  width: 100%;
  display: flex;
  height: 48px;
  border-radius: 14px;
  border: 1px solid var(--divider);
  align-items: center;
  padding: 4px;

  .input-icon {
    position: relative;
    left: 0;
  }

  .form-control {
    min-height: auto;
    height: 100%;
    border: none;
    border-radius: 0;
    padding-left: 12px;
    margin-top: 8px;
    max-width: 124px;
  }
}

.page__filters__input__label {
  position: absolute;
  top: 0;
  left: 12px;
  font-size: 10px;
}

.custom-search-select {
  font-family: var(--font-bold);
  font-size: 14px;
  line-height: 24px;
  border: 1px solid var(--divider);
  border-radius: 14px;
  transition: all .3s ease;
  overflow: hidden;
}

.custom-search-select-control {
  padding: 0 12px;
  position: relative;
  display: flex;
  align-items: center;
  background-position: 12px center !important;
  background-repeat: no-repeat !important;

  .form-control {
    background: transparent;
    border: none;
    padding-left: 40px;
    padding-right: 24px;
  }

  svg {
    pointer-events: none;
    position: absolute;
    right: 12px;
    transition: all .3s ease;
  }
}

.custom-search-select-list {
  position: relative;
  padding: 12px 12px 4px;
  max-height: 204px;
  overflow: auto;
  color: var(--text-muted);
  display: none;

  input:checked + span {
    color: var(--text-dark);
  }
}

.custom-search-select.visible {
  border-color: var(--orange);

  .custom-search-select-control {
    &:after {
      content: '';
      position: absolute;
      left: 8px;
      bottom: 0;
      height: 1px;
      background-color: var(--bg-secondary);
      width: calc(100% - 16px);
    }

    svg {
      transform: rotate(-180deg);
    }
  }

  .custom-search-select-list {
    display: block;
  }
}

.custom-search-select.visible:hover .select-languages-list {
  display: block;
}


.sider-ln {
  font-size: 14px;
  margin-right: 16px;
  font-family: var(--font-bold);
  cursor: pointer;
  transition: all .3s ease;
}

.sider-ln.active {
  color: var(--orange);
}

.sider-ln:hover {
  text-decoration: underline;
}

.zoundo-apiKey-success {
  display: none;
}

.zoundo-apiKey-success.visible {
  display: block;
  margin-top: 4px;
}

.content-padding {
  padding: 0 28px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.page__group__item {
  max-width: 220px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-radius: 30px;
  height: 40px;
  cursor: pointer;
  transition: all .3s ease;
  border: 1px solid transparent;
  font-family: var(--font-bold);
  white-space: nowrap;
  text-decoration: none;
  color: var(--text-dark);

  &:hover {
    background-color: var(--bg-secondary);
    color: var(--text-color);

    .page__group__status__item-icon {
      div svg {
        path {
          stroke-opacity: 1;
        }
      }
    }
  }

  .page__group__status__item-icon {
    font-size: 12px;
    width: 24px;
    min-width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 4px;
    margin-right: -12px;
    background-size: 16px;
    opacity: 0.64;
  }

  &.active {
    background: linear-gradient(#201F24, #201F24) padding-box, var(--gradient) border-box;
    color: var(--text-color);

    &.approved {
      .page__group__status__item-icon {
        background-color: var(--green);
        color: var(--text-color);
      }
    }

    &.rejected {
      .page__group__status__item-icon {
        background-color: var(--red);
        color: var(--text-color);
      }
    }

    &.in-review {
      .page__group__status__item-icon {
        background-color: var(--purple);
        color: var(--text-color);
      }
    }

    .page__group__status__item-icon {
      background-color: var(--bg-secondary);
      opacity: 1;
    }


  }
}

.separator {
  width: 100%;
  height: 1px;
  background-color: var(--bg-secondary);
}

.card-active {
  position: relative;
  cursor: pointer;
  border-radius: 24px;

  &:after {
    content: '';
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border: 1px solid transparent;
    background: linear-gradient(#2F2E33, #2F2E33) padding-box, var(--gradient) border-box;
    transition: var(--transition);
    border-radius: 24px;
    opacity: 0;
  }

  &:hover:after {
    opacity: 1;
  }
}

.card-body {
  position: relative;
  z-index: 1;
}

.card.white {
  background-color: #F7F7FA;
  border: 4px solid var(--divider);

  &:hover {
    background-color: var(--bg-main);
  }
}

.card.free {
  border-color: var(--green);
}

.card__item__status-icon.status-icon {
  font-size: 12px;
  width: 24px;
  min-width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--bg-main);
  background-size: 70%;
}

.card__item__status-icon div {
  display: flex;
  align-items: center;
}

.ws-nw {
  white-space: nowrap;
}

.bdrs-4 {
  border-radius: 24px;
}

a.text-muted:hover, .text-muted.cursor-pointer:hover {
  color: var(--text-color) !important;
}

.react-responsive-modal-overlay {
  background: rgba(45, 45, 45, 0.64);
}

.react-responsive-modal-modal {
  background-color: var(--bg-main);
  max-width: calc(100% - 48px);
  border-radius: 40px;
}

.bdrs-20 {
  border-radius: 20px;
}

.action-icon {
  cursor: pointer;
  transition: var(--transition);
  color: var(--text-muted);

  &:hover {
    color: var(--text-dark);
  }
}

.search-input {
  position: relative;
  display: flex;
  align-items: center;

  input.form-control {
    width: 220px;
  }
}

.search-input.small {
  min-width: 32px;
  width: 32px;
  height: 32px;
  overflow: hidden;
  transform: translateX(calc(100% - 32px));

  border-radius: 50%;

  input.form-control {
    min-height: 32px;
    height: 32px;
    border-radius: 10px;
    padding-left: 40px;
  }

  .input-icon {
    width: 32px;
    height: 32px;
    min-width: 32px;
    border-radius: 50%;
    left: 0;
  }
}

.search-input.small:hover, .search-input.small.visible {
  overflow: auto;
  width: auto;
  transform: translateX(0);
  border-radius: 0;
  //transition: var(--transition);
  .input-icon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    border-radius: 6px;
    left: 4px;
  }
}


.pagination-limit {
  min-width: 20px;
  width: auto;
  max-width: 70px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid var(--divider);
  margin-left: 8px;
  display: flex;
  align-items: center;
  padding: 0 24px 0 8px;
  line-height: 20px;
  background-position: calc(100% - 4px) center;
}

.alert-warning {
  background-color: var(--bg-secondary);
  border-color: var(--bg-secondary);
  border-radius: 24px;
  color: var(--text-dark);
}

.btn-outline-primary {

}

.btn-outline-danger {
  color: #972a2a !important;
}

.btn-outline-danger:hover {
  color: var(--text-color) !important;
}

.title-icon {
  width: 40px;
  height: 40px;
  min-width: 40px;
  background-color: var(--purple);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.text-tint {
  color: var(--purple);
}

.title-icon svg {
  width: 26px;
  height: 26px;
}

.title-icon path {
  fill-opacity: 1;
}

.close-btn {
  width: 40px;
  height: 40px;
  min-height: 40px;
  min-width: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.close-btn > div {
  position: relative;
  z-index: 1;
}

.bg-gradient {
  position: relative;
}

.bg-gradient:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(to right, #CC4DDF 0%, #64C0E0 35%, #1E46E8 100%);
  filter: blur(40px);
  border-radius: 24px;
}

.cur-pointer {
  cursor: pointer;
}

.pagination {
  display: flex;
  align-items: center;
  border-radius: 16px;
  background-color: var(--bg-secondary);
  padding: 8px 16px 8px 8px;
  font-size: 14px;
}

.pagination .btn {
  background: rgba(255, 255, 255, 0.1);

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  &:disabled {
    background-color: transparent !important;
  }
}

.pagination select {
  padding-right: 20px !important;
  background-position-x: calc(100% - 4px) !important;
  background-size: 16px !important;
  height: 32px !important;
  min-height: 32px !important;
  padding-top: 0;
  padding-bottom: 0;
}

.pagination .form-control {
  min-height: 40px;
  padding-right: 0;
  padding-left: 16px;
}

.card {
  transition: var(--transition);
  background-color: var(--bg-secondary);
}

.download-text {
  cursor: pointer;
  font-family: var(--font-bold);
  transition: var(--transition);
}

.text-gradient {
  background: linear-gradient(96.37deg, #CC4DDF 13.48%, #64C0E0 34.4%, #1E46E8 100.92%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-status {
  &.approve {
    color: var(--green);
  }

  &.reject {
    color: var(--red);
  }

  &.review {
    color: var(--text-muted);
  }
}

.bg-dark {
  background-color: var(--bg-main) !important;
}

.bdrs-16 {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
}


.Toastify__toast-theme--light {
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  font-family: var(--font-medium);
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 16px;
  background: linear-gradient(var(--bg-main), var(--bg-main)) padding-box, var(--gradient) border-box;
}

.Toastify__progress-bar--error {
  background: var(--red);
}

.breadcrumbs {
  color: var(--text-muted);
  font-family: var(--font-medium);

  a {
    color: var(--text-muted);
    text-decoration: none;

    &:hover {
      color: #fff;
    }
  }

  .current {
    color: var(--purple);
  }
}

.question-circle {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.animate-slide-left {
  animation: .5s show ease;
}

.animate-slide-right {
  animation: .5s slide-left ease;
}

@keyframes slide-left {
  from {
    transform: translateX(50px);
  }
  to {
    transform: translateX(0px)
  }
}

@keyframes slide-right {
  from {
    transform: translateX(-50px);
  }
  to {
    transform: translateX(0px)
  }
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;

}

hr {
  opacity: 0.1;
}

table {
  border-collapse: separate;
  border-spacing: 0 16px;
}

.table-head {
  display: flex;
  width: 100%;
  padding-bottom: 8px;
  line-height: 17px;
  color: var(--text-muted);
  font-size: 14px;
  font-family: var(--font-medium);
  border-bottom: 1px solid var(--divider);
}

.table-item {
  margin-top: 16px;
  background: rgba(255, 255, 255, 0.1);
  transition: var(--transition);
  border-radius: 24px;
  padding: 16px 8px;

  td {
    position: relative;
    z-index: 1;
  }
}

.performers-header-list {
  display: flex;
  position: relative;
  z-index: 1;
  color: var(--muted-color);
  margin-bottom: 32px;
  flex-wrap: wrap;
}

.react-icon > div {
  display: flex;
  align-items: center;
  justify-content: center;
}


.btn-md {
  min-height: 48px;
  min-width: 48px;
  padding-right: 24px;
  padding-left: 24px;
}

.btn-lg {
  min-height: 80px;
  min-width: 80px;
  padding-right: 32px;
  padding-left: 32px;
  border-radius: 40px;
  font-size: 20px;
  line-height: 30px;
}


.btn-sm {
  padding: 0 24px;
  min-height: 36px;
  min-width: 36px;
  border-radius: 18px;
  font-size: 12px;
  line-height: 21px;
}

.btn-xs {
  min-height: 24px;
  min-width: 24px;
}

.btn:disabled {
  background: rgba(255, 255, 255, 0.1) !important;
  color: rgba(255, 255, 255, 0.1) !important;
  transition: none !important;

  path {
    fill-opacity: 0.1;
    stroke-opacity: 0.1;
  }
}

.warning-line-with-btn {
  background-color: rgba(252, 70, 70, 0.24);
  display: inline-flex;
  align-items: flex-start;
  padding-right: 6px;
  border-radius: 24px;
  line-height: 24px !important;
  font-size: 14px;

  .warning-line-with-btn-icon  {
    width: 40px;
    height: 40px;
    min-width: 40px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    margin-left: 4px;
    margin-top: 4px;
    &.info {
      background: url("../../assets/images/info_red.svg") no-repeat center center;
      background-color: var(--bg-main);
    }
    &.warning {
      background: url("../../assets/images/warning.svg") no-repeat center center;
      background-color: var(--bg-main);
    }
    &.star {
      background: url("../../assets/images/stars.svg") no-repeat center center;
      background-color: var(--bg-main);
    }
  }

  button {
    margin-top: 6px;
  }
  .text-danger {
    padding: 12px 16px;
  }
}

.status-icon {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px;
  &.draft {
    background-image: url("../../assets/images/draft.svg");
  }
  &.review {
    background-image: url("../../assets/images/eye.svg");
  }
  &.review.review-clock {
    background-image: url("../../assets/images/clock.svg");
  }
  &.approve {
    background-image: url("../../assets/images/check.svg");
  }
  &.reject {
    background-image: url("../../assets/images/reject_red.svg");
  }
}

.icon-trash {
  width: 24px;
  height: 24px;
  min-width: 24px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px;
  background-image: url("../../assets/images/trash.svg");
}

.icon-edit {
  width: 24px;
  height: 24px;
  min-width: 24px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px;
  background-image: url("../../assets/images/edit.svg");
}

.icon-question {
  width: 24px;
  height: 24px;
  min-width: 24px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 24px;
  background-image: url("../../assets/images/question.svg");
}

html[dir='rtl'] {
  .me-0,
  .mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .ms-0,
  .mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .me-1,
  .mx-1 {
    margin-right: 0 !important;
    margin-left: 0.25rem !important;
  }

  .ms-1,
  .mx-1 {
    margin-left: 0 !important;
    margin-right: 0.25rem !important;
  }

  .me-2,
  .mx-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem !important;
  }

  .ms-2,
  .mx-2 {
    margin-left: 0 !important;
    margin-right: 0.5rem !important;
  }


  .me-3,
  .mx-3 {
    margin-right: 0 !important;
    margin-left: 1rem !important;
  }

  .mb-3,
  .my-3 {
    margin-bottom: 1rem !important;
  }

  .ms-3,
  .mx-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
  }

  .me-4,
  .mx-4 {
    margin-right: 0 !important;
    margin-left: 1.5rem !important;
  }

  .ms-4,
  .mx-4 {
    margin-left: 0 !important;
    margin-right: 1.5rem !important;
  }

  .me-5,
  .mx-5 {
    margin-right: 0 !important;
    margin-left: 3rem !important;
  }


  .ms-5,
  .mx-5 {
    margin-left: 0 !important;
    margin-right: 3rem !important;
  }

  .pe-0,
  .px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }


  .ps-0,
  .px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .pe-1,
  .px-1 {
    padding-right: 0 !important;
    padding-left: 0.25rem !important;
  }


  .ps-1,
  .px-1 {
    padding-left: 0 !important;
    padding-right: 0.25rem !important;
  }


  .pe-2,
  .px-2 {
    padding-right: 0 !important;
    padding-left: 0.5rem !important;
  }


  .ps-2,
  .px-2 {
    padding-left: 0 !important;
    padding-right: 0.5rem !important;
  }


  .pe-3,
  .px-3 {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }

  .ps-3,
  .px-3 {
    padding-left: 0 !important;
    padding-right: 1rem !important;
  }

  .pe-4,
  .px-4 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }

  .pb-4,
  .py-4 {
    padding-bottom: 1.5rem !important;
  }

  .ps-4,
  .px-4 {
    padding-left: 0 !important;
    padding-right: 1.5rem !important;
  }

  .pe-5,
  .px-5 {
    padding-right: 0 !important;
    padding-left: 3rem !important;
  }


  .ps-5,
  .px-5 {
    padding-left: 0 !important;
    padding-right: 3rem !important;
  }

  header .logo {
    margin-right: 0;
    margin-left: 56px;
  }

  header .nav a {
    margin-right: 0;
    margin-left: 4px;
  }

  header .avatar {
    margin-left: 0 !important;
  }

  .lang-select-visible {
    padding-left: 8px;
    padding-right: 4px;
  }

  .title-icon {
    margin-right: 0;
    margin-left: 8px;
  }

  .warning-line-with-btn {
    padding-right: 0;
    padding-left: 6px;
  }

  .albums-list-item-controls {
    margin-left: 0;
    margin-right: 12px;
  }

  .music-platforms {
    margin-left: 0;
    margin-right: 10px;
  }

  .performer-wrap + .album-head-wrap {
    margin-left: 0;
    margin-right: 24px;
  }

  .performer .avatar-logo, .album-head-cover {
    margin-right: 0;
    margin-left: 16px;
  }

  .btn-icon {
    margin-left: 0;
  }
  .header-popover-content {
    right: auto;
    left: 0;
    margin-right: 0;
    margin-left: 0px;
  }
  .notifications__container__items__item img {
    margin-right: 0;
    margin-left: 12px;
  }
  .form-file-icon {
    margin-right: 0;
    margin-left: 12px;
  }
  .track-cover-requirements {
    right: auto;
    left: 12px;
  }
  .form-control-icon {
    padding-right: 64px;
    padding-left: 12px;
  }
  .input-icon {
    left: auto;
    right: 4px;
  }
  .form-file-label {
    padding-left: 12px;
    padding-right: 12px;
  }
  .form-group-file {
    padding-right: 4px;
  }
  .moder-album-item-content img {
    margin-left: 16px;
  }
  .service-arpeggy {
    .performer-compose-tabs {
      padding-right: 0;
      padding-left: 40px;
    }
  }
}


.top-warning {
  padding: 4px 0 4px 40px;
  background: rgba(252, 70, 70, 0.24);
  color: var(--red);
  font-size: 12px;
  line-height: 18px;
  display: flex;
  p {
    margin-bottom: 0;
  }
}
.top-warning-icon {
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 64px;
  margin-right: 10px;
  background: url("../../assets/images/info_red.svg") no-repeat center center, var(--bg-main);
}