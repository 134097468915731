.info-helper {
  position: relative;
  margin-left: 12px;

}
.info-helper-hidden {
  position: absolute;
  bottom: 100%;
  background: #07060C;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  padding: 16px 24px;
  width: 390px;
  display: none;
  z-index: 100;
}
.info-helper:hover .info-helper-hidden {
  display: block;
}