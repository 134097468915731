.reports-page.filter-visible {
  .report-tabs .btn .report-chevron {
    transform: rotate(180deg);
  }
  .reports-total {
    width: calc(100vw - 130px - 332px);
    min-width: 818px;
  }
}

.report-chevron {
  transition: var(--transition);
}

.report-filters-group {
  display: flex;
  align-items: flex-end;
  margin-bottom: -1px;
}


.report-filters-content {
  flex: 1;
  padding-bottom: 82px;
  img {
    border-radius: 16px;
  }
}

.report-filters {
  width: 300px;
  min-width: 300px;
  margin-left: 32px;
  display: none;

  &.visible {
    display: block;
  }
}

.report-filters-block {
  background: var(--bg-secondary);
  border-radius: 32px;
}

.report-filters-block-head {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}


.report-filters-block-body-item {
  padding: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);

  &:last-child {
    border: none;
  }
}

.report-filters-block-body-content {
  display: none;
  padding-top: 16px;

  input[type='date'] {
    font-size: 12px !important;

    &:before {
      display: none;
    }
  }
}

.report-filters-block-body-item.visible {
  .report-chevron {
    transform: rotate(180deg);
  }

  .report-filters-block-body-content {
    display: block;
  }
}

.reports-list-head {
  display: flex;
  padding: 16px 32px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  align-items: center;
  font-size: 14px;
  line-height: 21px;
  color: var(--muted-color);
  width: 100%;
  padding-right: 40px;
}

.reports-list-number-item {
  min-width: 110px;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--muted-color);
  font-size: 14px;
}

.report__list__item {
  display: flex;
  align-items: center;
  padding: 0 32px;
  margin-top: 8px;

  .content-type-item-img {
    width: 140px;
    height: 80px;
    min-width: 140px;
    cursor: pointer;

    .content-type-item-play {
      width: 48px;
      height: 48px;

      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
}

.report__list__item__content {
  border: 1px solid rgba(255, 255, 255, 0.05);
  display: flex;
  background: rgba(255, 255, 255, 0.05);
  transition: var(--transition);
  padding: 8px;
  flex: 1;
  border-radius: 16px;

  &:hover {
    background: rgba(255, 255, 255, 0.15);

    .report__list__item-icon .react-icon {
      opacity: 1;
    }
  }
}

.report__list__item-icon {
  width: 80px;
  height: 80px;
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  margin-right: 16px;

  .react-icon {
    opacity: 0.5;
    transition: var(--transition);

    svg {
      width: 40px;
      height: 40px;
    }
  }
}

.report-filters-selected-list-wrap {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 8px;
    background: linear-gradient(to top, #202020 0%, transparent 100%);
  }
}

.report-filters-selected-list {
  max-height: 80px;
  overflow: auto;


}

.report-filters-selected-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.05);
  padding-left: 24px;
  padding-right: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 20px;
}

.report-filter-count {
  background: var(--tint-color);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  border-radius: 40px;
  font-size: 14px;
  line-height: 20px;
  font-family: var(--font-refular);
  color: var(--bg-main);
  padding: 0 6px;
}

.report-filters-count {
  height: 24px;
  padding-right: 8px;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.1);
  padding-left: 12px;
  display: flex;
  align-items: center;
  margin-left: 8px;
  font-size: 14px;
  line-height: 20px;
  //&.accent {
  //  color: var(--bg-main);
  //  background-color: var();
  //  svg path{
  //    //stroke: var(--bg-main)
  //  }
  //}
}


.reports-list-item {
  min-height: 96px;
}

.reports-list-item:hover {
  .player-id {
    opacity: 0;
  }

  .player .ctrl {
    opacity: 1;
  }
}


.reports-tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  .report-filter-count {
  background: var(--gradient);
    color: #fff;
  }
}

.reports-total {
  position: fixed;
  bottom: 16px;
  display: flex;
  left: 90px;
  background: var(--bg-secondary);
  backdrop-filter: blur(20px);
  border-radius: 24px;
  align-items: center;
  padding-left: 24px;
  padding-right: 4px;
  height: 50px;
  color: var(--text-muted);
  z-index: 1;
  width: calc(100vw - 130px);
  min-width: 1150px;
}