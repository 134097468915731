.player {
  cursor: pointer;
  position: relative;
  min-width: 72px;
  width: 72px;
  height: 72px;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
  }
  .ctrl {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    opacity: 0;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.64);
    transition: all ease 0.3s;
    &-animation {
      opacity: 1;

      .box {
        height: 16px;
        overflow: hidden;
        .bar {
          display: inline-block;
          height: 16px;
          width: 2px;
          margin: 0 1px;
          background-color: #fff;

          $animationLength: 0.9s;
          animation: wobble $animationLength infinite;
          animation-timing-function: cubic-bezier(0.4, 0.2, 0.6, 0.8);
          animation-fill-mode: both;

          &:nth-child(3) {
            $delay: calc(-1 * $animationLength / 4);
            animation-delay: $delay;
          }

          &:nth-child(2) {
            $delay: calc(-2 * $animationLength / 4);
            animation-delay: $delay;
          }

          &:nth-child(4) {
            $delay: calc(-3 * $animationLength / 4);
            animation-delay: $delay;
          }
        }
      }
    }
  }
  &:hover {
    .ctrl {
      color: #fff;
    }
    .player-id {
      opacity: 0;
    }
  }
}

@keyframes wobble {
  0% {
    transform: translatey(0%); //top
  }
  25% {
    transform: translatey(70%);
  }
  50% {
    transform: translatey(90%); //bottom
  }
  75% {
    transform: translatey(50%);
  }
  100% {
    transform: translatey(0%);
  }
}
.player-id {
  position: absolute;
  font-family: var(--font-medium);
  color: rgba(255, 255, 255, 0.64);
  transition: var(--transition);
  opacity: 1;
}