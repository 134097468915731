.avatar {
  display: flex;
  align-items: center;
}
.avatar-logo {
  width: 64px;
  min-width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #1E46E8;
  color: rgba(255, 255, 255, 0.64);
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 24px;
  font-family: var(--font-bold);
}