header {
  background: rgba(217, 217, 217, 0.1);
  backdrop-filter: blur(20px);
  //color: var(--text-muted);
  padding: 0 40px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  z-index: 99;
  /*    border-radius: 0 0 24px 24px;*/
  .logo {
    width: 49px;
    height: 47px;
    user-select: none;
    margin-right: 56px;
  }

  .nav {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
    align-items: center;
    left: 0;

    a {
      white-space: nowrap;
      padding: 8px 20px;
      text-decoration: none;
      font-family: var(--font-bold);
      color: var(--text-dark);
      border-radius: 30px;
      margin-right: 4px;
      border: 1px solid transparent;
      transition: var(--transition);

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: var(--text-color);
        background-color: #323237;
      }

      &:not(.disabled-active).active {
        color: var(--text-color);
        background: linear-gradient(#323237, #323237) padding-box, var(--gradient) border-box;
      }
    }

    &-btn {
      width: 48px;
      height: 48px;
      min-width: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #323237;
      border-radius: 50%;
      color: var(--text-dark);

      border: 1px solid transparent;

      &:hover {
        transition: all ease 0.3s;
        background: linear-gradient(#323237, #323237) padding-box, var(--gradient) border-box;
        color: var(--text-color);
      }
    }
  }

  .balance {
    font-size: 14px;
    font-family: var(--font-medium);
    padding-left: 24px;
    background: url("../../assets/images/dollars.svg") no-repeat left center;
    background-size: 20px;
  }
}

.computed-menu {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  left: 0;
  width: 100%;
  display: flex;

  div {
    padding: 0 20px;
    margin-right: 4px;
    white-space: nowrap;
  }
}

.hidden-menu {
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    .menu-more {
      border-color: var(--divider);
      color: var(--text-dark);
      background: var(--bg-main);

      svg {
        transform: rotate(-180deg);
      }
    }

    .hidden-menu-list {
      display: flex;
    }
  }
}

.hidden-menu-list {
  position: absolute;
  top: calc(100% - 1px);
  min-width: calc(100% + 24px);
  left: 0;
  background: var(--bg-main);
  display: none;
  padding: 8px;
  border-radius: 0 30px 30px 30px;
  border: 1px solid var(--divider);
  flex-direction: column;
  z-index: 21;

  a {
    line-height: 24px;
    position: relative;

    &:first-child:after, &:hover:after, &:hover + a:after, &.active:after, &.active + a:after, {
      display: none;
    }
  }

  a:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--bg-secondary);
  }

}

.menu-more {
  border: 1px solid transparent;
  border-radius: 30px 30px 0 0;
  padding: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  font-family: var(--font-bold);
  color: var(--text-muted);

  svg {
    transition: all .3s ease;
  }
}

.header-profile {
  position: relative;
}

.header-profile {
  .header-popover-content {
    margin-right: 0;
    padding: 8px;
    width: 250px;
  }

  .avatar-logo {
    width: 48px;
    height: 48px;
    min-width: 48px;
    font-size: 18px;
  }

  button {
    color: var(--text-dark);
    width: 100%;
    background-color: var(--bg-secondary) !important;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2) !important;
    }

    .btn-icon {
      position: absolute;
      left: 32px;
    }
  }
}

.header-profile:hover .header-profile-hidden {
  opacity: 1;
  pointer-events: auto;
}

.header-profile-hidden:after {
  content: '';
  position: absolute;
  left: -24px;
  top: -24px;
  bottom: -24px;
  right: -24px;
  background: linear-gradient(96.37deg, #CC4DDF 13.48%, #64C0E0 34.4%, #1E46E8 100.92%);
  filter: blur(50px);
  border-radius: 24px;
}

.header-profile-hidden-content {
  padding: 16px;
  position: relative;
  z-index: 1;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  backdrop-filter: blur(20px);
}

.header-profile-hidden-content:after {
  content: '';
  position: absolute;
  top: -12px;
  right: 24px;
  width: 25px;
  height: 25px;
  border: inherit;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  transform: rotate(135deg);
  border-radius: 0 0 0 0.25em;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
}

.header-profile-hidden-content:before {
  content: '';
  position: absolute;
  top: -12px;
  right: 24px;
  width: 25px;
  height: 25px;
  background: linear-gradient(96.37deg, #CC4DDF 13.48%, #64C0E0 34.4%, #1E46E8 100.92%);
  backdrop-filter: blur(50px);
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  transform: rotate(135deg);
  border-radius: 0 0 0 0.25em;
  opacity: .1;
}

.header-profile-hidden-settings {
  font-size: 14px;
  color: var(--text-dark);
  transition: var(--transition);
  cursor: pointer;
}

.header-profile-hidden-settings:hover {
  color: var(--text-color)
}

.header-popover {
  position: relative;
}

.header-popover-content {
  display: none;
  overflow: hidden;
  user-select: none;
  position: absolute;
  top: calc(100% + 24px);
  right: 0;
  width: 372px;
  border-radius: 24px;
  margin-right: 32px;
  background: linear-gradient(var(--bg-main), var(--bg-main)) padding-box, var(--gradient) border-box;
  border: 1px solid transparent;
}

.header-popover.active {
  .header-popover-content {
    display: block;
  }
}
