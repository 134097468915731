.form-group {
  display: flex;
  flex-direction: column;
  position: relative;
}

.form-group label {
  font-size: 14px;
  line-height: 21px;
  padding-left: 8px;
}

.form-control-wrap {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 16px;
}

.input-icon {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  width: 48px;
  height: 48px;
  position: absolute;
  left: 4px;
  top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-control {
  width: 100%;
  font-size: 16px;
  font-family: var(--font-bold);
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  min-height: 56px;
  z-index: 1;
  position: relative;
}

select.form-control {
  background: url("../../assets/images/chevron.svg") no-repeat calc(100% - 8px) center;
  background-color: var(--bg-secondary);
  color: var(--text-muted);
  &:has(option[value=""]:not(:checked)) {
    color: #fff!important;
  }
}

select.form-control:focus {
}

.form-control::placeholder {
  font-size: 16px;
  font-family: var(--font-regular) !important;
  color: rgba(255, 255, 255, 0.4);
}

.form-control:focus {
  color: #fff;
  outline: 0;
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid transparent;
}

.form-control-gradient {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  filter: blur(50px);
  border-radius: 16px;
  background: var(--gradient);
  opacity: 0;
  pointer-events: none;
  transition: var(--transition);
}

.form-control:focus + .form-control-border + .form-control-gradient {
  opacity: 1;
}

.form-control-border {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  background: linear-gradient(var(--bg-main), var(--bg-main)) padding-box, var(--gradient) border-box;
  border: 1px solid transparent;
  opacity: 0;
  pointer-events: none;
  border-radius: 16px;
}

.form-control:focus + .form-control-border {
  opacity: 1;
}

.form-control-icon {
  padding-left: 64px;
}

.form-control:focus + .form-control-border + .form-control-gradient + .input-icon path {
  fill-opacity: 1;
  stroke-opacity: 1;
}

.form-group-error {
  position: absolute;
  right: 0;
  color: var(--re);
  font-size: 16px;
}

.form-group-file {
  position: relative;
  font-size: 16px;
  display: flex;
  align-items: flex-start;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  min-height: 56px;
  padding: 12px;
  padding-bottom: 4px;
}

.form-group.error .form-group-file {
  border-color: var(--red);
}

.form-file-label {
  color: var(--text-color)!important;
  font-family: var(--font-bold);
  cursor: pointer;
  position: relative;
  height: 48px;
  font-size: 16px !important;
  border-radius: 40px;
  background: linear-gradient(#36353A, #36353A) padding-box, var(--gradient) border-box;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  padding: 0 20px 0 12px;
  transition: var(--transition);
  &:hover {
    background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)) padding-box, var(--gradient) border-box;
  }
}

.form-file-label-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding-right: 4px;
  flex: 1;
}


.form-file-label path {
  transition: var(--transition);
}

.form-file-label:hover path {
  stroke-opacity: 1;
}

.form-group-file input {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}

.form-file-list-item {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: 16px;
  height: 32px;
  color: var(--text-muted);
  padding: 0 8px 0 12px;
  max-width: 202px;
  margin-bottom: 4px;
  margin-right: 8px;
  line-height: 20px;
  cursor: default;
}

.form-file-list-item:hover {
  color: var(--text-color)
}

.form-file-list-item:hover path {
  fill-opacity: 1;
}

.form-group-file-placeholder {
  font-family: var(--font-regular);
  color: rgba(255, 255, 255, 0.4);
}

.form-group-file .input-icon + .form-group-file-placeholder {
  padding-left: 60px;
}

.form-group-file .input-icon {
  left: 3px;
  top: 3px;
}

textarea.form-control {
  resize: none;
  padding-top: 16px;
  padding-bottom: 16px;
  min-height: 124px;
}

.input-pass-eye {
  cursor: pointer;
  position: absolute;
  right: 16px;
  width: 32px;
  height: 32px;
  z-index: 1;
}

.form-password-forgot {
  margin-right: 16px;
  cursor: pointer;
  font-size: 14px;
  color: var(--text-muted);
  transition: var(--transition);
}

.form-password-forgot:hover {
  text-decoration: underline;
}

.input-file__progress {
  min-width: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
}

.form-file-icon {
  width: 104px;
  height: 104px;
  min-width: 104px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  margin-left: -8px;
  margin-top: -8px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 12px;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
}

.form-group.error .form-control {
  border-color: var(--red);
}

.form-control option {
  background: var(--bg-main);
  font-family: var(--font-regular);
  margin-left: -32px;
  &.active {
    background-color: #2B2B2B;
  }

  &:hover {
    background-color: #202020 !important;
  }

  &:disabled {
    opacity: 0;
    font-size: 0;
  }
}

select.form-control[name= ''] {
  font-family: var(--font-regular);
  font-size: 16px;
  color: var(--text-muted);
}

.form-control[type= 'date'] {
  font-size: var(--font-regular);
  color: var(--text-muted);
}

.form-control[type= 'date']:valid {
  color: var(--text-color);
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
}

input[type="date"]:before {
  content: '';
  height: 20px;
  width: 20px;
  pointer-events: none;
  background: url("../../assets/images/calendar.svg") no-repeat center;
  top: 18px;
  /* This is the calendar icon in FontAwesome */
  position: absolute;
  right: 12px;
  color: #999;
}

.input-group {
  display: flex;
  align-items: flex-end;
}

.input-group .form-control {
  border-right-width: 0;
  border-radius: 0;
}

.input-group .form-control-border {
  border-radius: 0;
}

.input-group .form-group:first-child {
  .form-control, .form-control-border {
    border-radius: 16px 0 0 16px;
  }
}

.input-group .form-group:last-child {
  .form-control, .form-control-border {
    border-radius: 0 16px 16px 0;
    border-right-width: 1px;
  }
}

@media only screen and (max-width: 992px) {
  .form-group-file {
    /*padding: 8px;*/
    padding: 8px 8px 4px;
  }
  .form-file-label-wrap {
    padding-right: 0px;
  }
  .form-control {
    min-height: 48px;
  }

  .input-icon {
    width: 40px;
    height: 40px;
  }

  .input-icon svg {
    width: 24px;
  }

  .form-control-icon {
    padding-left: 52px;
  }

  textarea.form-control {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .form-control::placeholder, .form-group-file-placeholder, .form-group-file {
    font-size: 14px;
  }

  .form-file-label {
    font-size: 14px;
    height: 32px;
    border-radius: 40px;
  }

  .form-file-label svg {
    width: 18px;
  }

  .form-password-forgot {
    font-size: 14px;
  }
  .form-group-error {
    font-size: 14px;
  }
}

.form-check {
  padding-left: 40px;
  user-select: none;
  position: relative;
  display: flex;
  align-items: flex-start;

}

.form-check-input {
  cursor: pointer;
  width: 24px;
  height: 24px;
  min-width: 24px;
  max-height: 24px;
  margin-top: 0;
  opacity: 0;

  &:focus {
    box-shadow: none;
  }
}

.form-check .form-check-input {
  margin-left: -40px;

  &:hover + .form-check-custom {
    border-color: var(--purple);
  }

  &:active + .form-check-custom {
    background-color: var(--purple);
  }

  &:checked + .form-check-custom {
    background-color: var(--purple);
    border-color: var(--purple);

    svg {
      display: block;
    }
  }
}

.form-check-label {
  font-family: var(--font-bold);
  font-size: 14px;
  color: var(--text-dark);
}

.form-check-custom {
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  border: 1px solid var(--divider);
  background-color: var(--bg-secondary);
  transition: var(--transition);
  color: var(--text-color);

  svg {
    display: none;
  }
}
.form-control:disabled, .form-control.disabled {
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0.3;

}


::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}
input[type='date']:in-range::-webkit-datetime-edit-year-field,input[type='date']:in-range::-webkit-datetime-edit-month-field,input[type='date']:in-range::-webkit-datetime-edit-day-field,input[type='date']:in-range::-webkit-datetime-edit-text{  color: transparent;}

input[type="date"]::-webkit-calendar-picker-indicator, input[type="time"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}


.form-control[type= 'date'] {
  font-size: var(--font-refular);
  color: var(--muted-color);
}

.form-control[type= 'date']:valid {
  color: var(--text-color);
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
}

input[type="date"]:before {
  content: '';
  height: 20px;
  width: 20px;
  pointer-events: none;
  background: url("../../assets/images/calendar.svg") no-repeat center;
  /* This is the calendar icon in FontAwesome */
  position: absolute;
  right: 12px;
  color: #999;
}

.form-control-placeholder {
  pointer-events: none;
  position: absolute;
  left: 24px;
  color: var(--text-muted) !important;
  z-index: 1;
}