.music-platforms {
  display: flex;
  height: 20px;
  align-items: center;
  transition: var(--transition);
  border-radius: 24px;
  margin-left: 10px;
  border: 1px solid transparent;
  path {
    transition: var(--transition);
  }
}

.music-platforms.visible {
  border-color: #fff;
  background: rgba(255, 255, 255, 0.1);
  .music-platforms-item-visible-icon path {
    fill-opacity: 1;
  }

  .music-platforms-list-item.union {
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
  }
}

.music-platforms-list-item {
  margin: 2px;
  transition: var(--transition);

  svg {
    width: 16px;
  }
}

.music-platforms-list-item.union {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  cursor: pointer;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  border-radius: 32px;
  width: 16px;
  height: 16px;
  min-width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.4);

  &:hover {
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
  }

  .music-platforms-list-item {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    margin: 0 2px;
  }
}

.music-platforms-union-list {
  position: absolute;
  background-color: #07060C;
  border: 1px solid rgba(255, 255, 255, 0.15);
  padding: 4px 16px 4px 4px;
  bottom: 32px;
  border-radius: 24px;
  display: none;
  width: 320px;
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;
  flex-wrap: wrap;

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    margin-top: 24px;
    margin-bottom: 24px;
    background: transparent;
  }

  h6 {
    margin-top: 12px;
    margin-bottom: 8px;
    color: #fff;
    margin-left: 12px;
  }
}

.music-platforms-list-item.union.visible {
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.1);

  .music-platforms-union-list {
    display: flex;
  }

}

.music-platforms-hidden-item {
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
}

.music-platforms-hidden-item-icon {
  min-width: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  margin-right: 8px;

  svg {
    width: 24px;
  }
}