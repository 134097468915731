.notifications {
  &__container {
    &__header {
      display: flex;
      justify-content: space-between;
      margin: 16px 24px;
      .title {
        font-family: var(--font-bold);
        font-size: 16px;
      }
      .delete-all {
        transition: var(--transition);
        cursor: pointer;
        font-size: 14px;
        color: var(--text-muted);
        &:hover {
          color: var(--text-dark);
        }
      }
    }

    &__telegram {
      background-color: var(--bg-secondary);
      padding: 8px;
      border-radius: 12px 12px 24px 24px;
      margin-bottom: 8px;
      .text1 {
        color: var(--text-muted);
        font-size: 12px;
        a {
          font-family: var(--font-bold);
          color: #6eaae1;
        }
        b {
          font-family: var(--font-bold);
          color: var(--text-color);
        }
        margin-right: 30px;
        margin-bottom: 8px;
      }

      .banner {
        background: #6eaae1 url('../assets/images/clouds.svg') no-repeat center bottom;
        border-radius: 20px;
        height: 122px;
        color: var(--bg-main);
        font-size: 14px;
        .line1,
        .line2 {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .line1 {
          padding-top: 16px;

          .telegram-logo {
            width: 90px;
            height: 19px;
            background: url('../assets/images/telegram.svg') no-repeat;
          }
          margin-bottom: 4px;
        }
        .line2 {
          opacity: 0.8;
        }
      }
    }

    &__items {
      max-height: 400px;
      overflow: auto;
      padding: 0 8px 8px;
      border-radius: 12px;
      &__item {
        transition: all ease 0.3s;
        padding: 8px;
        margin-bottom: 4px;
        display: flex;
        align-items: flex-start;
        cursor: pointer;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          left: 8px;
          top: -2px;
          width: calc(100% - 16px);
          height: 1px;
          background-color: var(--divider);
          border-radius: 24px;
        }
        &:last-child {
          border-bottom: 0;
          margin-bottom: 0;

        }
        img {
          margin-right: 12px;
          width: 40px;
          height: 40px;
          border-radius: 8px;
        }
        .text {
          flex-grow: 1;
          font-size: 12px;
          line-height: 18px;
          color: var(--text-dark);
          transition: all ease 0.3s;
          .date {
            font-size: 12px;
            line-height: 18px;
            margin-top: 4px;
            color: var(--text-dark);
          }
        }
        &:hover {
          .text {
            color: var(--text-dark);
          }
        }
        button {
          opacity: 0;
          transition: all ease 0.3s;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0;
          margin-left: 8px;
          background-color: transparent;
          padding: 0;
          width: 16px;
          height: 16px;
          cursor: pointer;
          color: var(--text-muted);
          &:hover {
            color: var(--text-dark);
          }
        }
        &.new,
        &:hover {
          border-radius: 12px;
          background-color: var(--bg-secondary);
          &:after {
            display: none;
          }
          & + .notifications__container__items__item:after {
            display: none;
          }
        }
        &:hover {
          background-color: var(--bg-secondary);
          button {
            opacity: 1;
          }
        }

      }
    }
  }
  &__bell {
    position: relative;
    &__badge {
      background-color: var(--purple);
      display: flex;
      width: 16px;
      height: 16px;
      border: 4px solid #1C1C21;
      border-radius: 50%;
      box-sizing: content-box;
      position: absolute;
      top: -5px;
      right: -5px;
      font-size: 10px;
      font-family: var(--font-medium);
      align-items: center;
      justify-content: center;
      line-height: 0;
    }
    &-active {
      color: var(--text-dark) !important;
    }
  }
  .header-popover-content {
    right: -112px;
  }
}
//
//// animation: heartbeat 1s infinite;
//
//@keyframes heartbeat {
//  0% {
//    box-shadow: 0 0 0 0 var(--bs-danger);
//  }
//  70% {
//    box-shadow: 0 0 0 10px rgba(199, 39, 44, 0);
//  }
//  100% {
//    box-shadow: 0 0 0 0 rgba(199, 39, 44, 0);
//  }
//}
//
//@keyframes fadeIn {
//  0% {
//    opacity: 0;
//  }
//  100% {
//    opacity: 100;
//  }
//}

.button-telegram {
  font-size: 14px;
  margin: 16px 8px;
  width: 324px;
  .icon {
    padding: 0;
    background: #6eaae1 url('../assets/images/telegram-plane.svg') no-repeat 3px center;
    background-size: 18px 15px;
    color: var(--text-color);
    font-size: 16px;
  }
  &:hover {
    color: var(--text-color);
    background-color: #6eaae1;
    .icon {
      color: var(--bg-main);
      background-color: #6eaae1;
    }
  }
}

.react-tiny-popover-container {
  z-index: 8000 !important;
  .arrow {
    :first-child {
      z-index: 9000 !important;
    }
  }
}

.notifications__container__items .message {
  white-space: break-spaces;
}

.notifications.empty {
  pointer-events: none;
}